import ITextVerificationService, { ISendVerificationTextResult } from "./ITextVerificationService";
import textVerificationAPI from "../api/textVerificationAPI";

class TextVerificationService implements ITextVerificationService {
    async sendVerificationText(mobile?:string, patientId?:string, sessionId?:string): Promise<ISendVerificationTextResult> {
        const result: ISendVerificationTextResult = await textVerificationAPI.sendText(mobile,patientId,sessionId);
        return result;
    }

    async verifyCode(key:string, code:string): Promise<boolean> {
        const verified = textVerificationAPI.verify(key,code);
        return verified;
    }

}

export default TextVerificationService;

