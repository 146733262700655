import React, { useEffect, useState } from "react";
import Header from "./Header";
import ErrorHandler from "./ErrorHandler";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MainForm from "./MainForm";
import { useContext } from "react";
import { PracticeContext } from "../contexts/PracticeContext";
import { FatalErrorContext } from "../contexts/FatalErrorContext";

const AppWithPractice = (props: any) => {
  const { fatalError } = useContext(FatalErrorContext);
  const { practice, setupComplete } = useContext(PracticeContext);

  const [progressState, setProgressState] = useState("Patient Details");

  useEffect(() => {
    if (!localStorage.getItem("sessionId")) {
      initializeSession();
    }
  }, []);

  const initializeSession = () => {
    const uniqid = require("uniqid");
    localStorage.setItem("sessionId", uniqid());
  };

  if(!setupComplete) {
    return <p>Loading Practice...</p>;
  }

  if (!fatalError) {
    if (practice) {
      const stripePromise = practice?.stripePublishableKey ? ( loadStripe(practice.stripePublishableKey)) : null;
      return (
        <Elements stripe={stripePromise}>
          <Header progressState={progressState} />
          <MainForm setProgressState={setProgressState} />
        </Elements>
      );
    } else {
      return <p>A problem occurred loading the practice</p>;
    }
  } else {
    return <ErrorHandler />;
  }
};

export default AppWithPractice;
