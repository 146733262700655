import React, {useState} from 'react'
import IWizardProps from './IWizardProps'
import Step from '../steps/Step'

const Wizard = ({defaultStep, children}: IWizardProps) => {
    const [wizardState, setWizardState] = useState<IWizardProps>({children: children, currentStep: defaultStep});

    const changeStep = (stepName: string) => {
        setWizardState({...wizardState, currentStep: stepName})
    };

    return (
        //map over steps and render based on name
        children.map((child) => {
            
            const { name } = child.props;
            
            const { currentStep } = wizardState;

            if (name == currentStep) {
                return React.cloneElement(child, {changeStep: changeStep, key: name})
            }
        }
    ))
};

Wizard.Step = Step;

export default Wizard