
import { ENDPOINT_SEND_VERIFICATION_TEXT, ENDPOINT_VERIFY_TEXT_CODE } from '../constants/endpoints'
import { postRequest } from "shared/API/rest"
import { ISendVerificationTextResult } from '../services/ITextVerificationService';


class TextVerificationAPI {
  sendText(mobile?:string, patientId?:string, sessionId?:string) {
      return postRequest<any>(ENDPOINT_SEND_VERIFICATION_TEXT,{mobile,patientId,sessionId}).then((response:any)=>{
          const result:ISendVerificationTextResult = {...response.data};
          return result;
      })  
  }

  verify(key:string,code:string) {
    return postRequest<any>(ENDPOINT_VERIFY_TEXT_CODE,{key,code}).then((verified:any)=>{
        return verified.data.success;
      })
  }
}

const textVerificationAPI = new TextVerificationAPI();
export default textVerificationAPI;
