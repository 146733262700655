import React from 'react'
import {stepList} from '../steps/Steps'

const ProgressBar = (progressState) => {
    
    const stepNo = stepList.indexOf(progressState.progressState) + 1;
    const totalStep = stepList.length;
    const progressWidth = (stepNo/totalStep) * 100;
    const progressPercentageWidth = convertToPercentageStr(progressWidth);
    const tooltipPercentageWidth = convertToPercentageStr(progressWidth - 7);

    return (
        <div className="c-progress u-pos-rel">
            <div className="c-tooltip" style={{left: tooltipPercentageWidth}}>{progressState.progressState}</div>
            <div className="c-progress__bar-container">
                <div className="c-progress__bar-filled" style={{width: progressPercentageWidth}} />
            </div>
        </div>
    )
}

const convertToPercentageStr = (num: number) => num.toString() + '%'

export default ProgressBar