import React, { useContext } from 'react'
import IStepProps from './IStepProps'
import IBooking from 'shared/Practices/Entities/IBooking'
import { PracticeRegion } from "shared/Practices/Entities/IPractice";
import { useForm } from 'react-hook-form'
import practiceAPI from 'shared/API/PracticeAPI'
import {BOOKING_COMPLETE_STEP, CALENDAR_STEP, DEPOSIT_STEP, GATHER_DETAILS_STEP, CAPTURED_DEPOSIT_STEP, TEXT_VERIFICATION_STEP} from '../steps/Steps'
import BookingCompletedStep from './BookingCompletedStep'
import DateOfBirthSelect from '../DateofBirthSelector'
import { PracticeContext } from '../../contexts/PracticeContext';

const GatherDetailsStep = ({changeStep, booking, submitData, setProgressState}: IStepProps) => {

    const { practice } = useContext(PracticeContext);

    const { register, handleSubmit, errors } = useForm<IBooking>();

    let inputClass = 'c-input';

    let healthCareNumDisplay = false;

    const getNextStep = () => {
        if(practice.textService && booking)
        {
            return TEXT_VERIFICATION_STEP;
        } else if (practice.depositService && booking && booking?.appointmentDeposit !== 0) {
            return CAPTURED_DEPOSIT_STEP;
        } else if (practice.paymentService) {
            return DEPOSIT_STEP;
        } else {
            return BOOKING_COMPLETE_STEP;
        }
    }

    const onSubmit = data => {

        const {
            year,
            month,
            day
        } = data

        //Month is 0 indexed - Jan = 00, Feb = 01 etc
        let dob = new Date(year,month-1,day);
        //prevent issues with timezones causing us to have wrong dates
        dob.setTime(dob.getTime() - dob.getTimezoneOffset()*60*1000 );

        const today = new Date();
        
        if(dob > today) {
            alert("Date of birth cannot be in the future.")
            return
        }

        let dataWithDate = {...data, dob}
        
        submitData && submitData(dataWithDate);
        
        changeStep && changeStep(getNextStep());
    };

    setProgressState && setProgressState(GATHER_DETAILS_STEP);

    if (practice?.region === PracticeRegion.NI && practice?.addHealthcareNumber) {
        healthCareNumDisplay = true;
    } 

    return (
        <div className="o-wrap +no-pad@m- +fixed-header">
            <div>
                <div className="u-pad">
                    {Object.keys(errors).length !== 0 && <div className="c-alert +error">Please review your submission below.</div>}
                    <p className="u-micro u-bold u-uppercase u-lighten">Book your appointment</p>
                    <div className="u-marg-bottom-x2">
                        <h1 className="u-delta u-marg-bottom-half">Booking Confirmation</h1>
                        <p className="u-lighten">We need some information in order to confirm your booking.</p>
                    </div>
                    <div className="u-marg-bottom-x2">
                        <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                            <fieldset>
                                <div className="u-marg-bottom">
                                    <div className="o-flex +flex-start u-marg-bottom">
                                        <div className="u-marg-right@m">
                                            <label className="c-label" htmlFor="forename">Forename</label>
                                            <input type="text" className="c-input" id="forename" name="forename" defaultValue={booking?.forename} ref={
                                                register({
                                                    required: { value: true, message: 'Forename is required.' },
                                                    validate: value => !value.match(/[^A-Za-zÀ-ÖØ-öø-ÿ\-]/g) || 'Illegal characters detected',
                                                    maxLength : {
                                                        value: 300,
                                                        message: 'Too long, limited to 300 characters' 
                                                      },
                                                })}
                                            />
                                            <p className="u-negative u-milli">{errors?.forename?.message}</p>
                                        </div>
                                        <div>
                                            <label className="c-label" htmlFor="surname">Surname</label>
                                            <input type="text" className="c-input" id="surname" name="surname" defaultValue={booking?.surname} ref={
                                                register({
                                                    required: {value: true, message: 'Surname is required.'},
                                                    validate: value => !value.match(/[^A-Za-zÀ-ÖØ-öø-ÿ\-]/g) || 'Illegal characters detected',
                                                    maxLength : {
                                                        value: 300,
                                                        message: 'Too long, limited to 300 characters' 
                                                      },
                                                })}
                                            />
                                            <p className="u-negative u-milli">{errors?.surname?.message}</p>
                                        </div>
                                    </div>
                                    <div className="u-marg-bottom">
                                        <DateOfBirthSelect register={register} booking={booking} errors={errors} inputClass={'c-input'}/>
                                    </div>
                                    <div className="u-marg-bottom u-1/2@m">
                                        <label className="c-label" htmlFor="mobileNumber">Mobile Number</label>
                                        <input type="text" className="c-input" id="mobileNumber" name="mobile" defaultValue={booking?.mobile} ref={
                                            register({
                                                required: { value: true, message: 'Mobile number is required.' },
                                                maxLength : {
                                                    value: 30,
                                                    message: 'Too long, limited to 30 characters' 
                                                  },
                                            })}
                                        />
                                        <p className="u-negative u-milli">{errors?.mobile?.message}</p>
                                    </div>
                                    <div className="u-marg-bottom u-1/2@m">
                                        <label className="c-label" htmlFor="sex">Sex</label>
                                        <select name="sex" id="sex" defaultValue={booking?.sex} ref={register}>
                                            <option value="Male" key="1">Male</option>
                                            <option value="Female" key="2">Female</option>
                                        </select>
                                    </div>
                                    {healthCareNumDisplay && <div className="u-marg-bottom">
                                        <label className="c-label" htmlFor="healthcareNum">Health & Care Number</label>
                                        <input type="text" className="c-input" id="healthcareNum" name="healthcareNum"
                                            defaultValue={booking?.healthcareNum} ref={
                                                register({
                                                    validate: value => !value.match(/[^A-Za-z0-9]/g) || 'Illegal characters detected',
                                                    maxLength : {
                                                        value: 30,
                                                        message: 'Too long, limited to 30 characters' 
                                                      },
                                                })}
                                        />
                                        <p className="u-negative u-milli">{errors?.healthcareNum?.message}</p>
                                    </div>}
                                    {practice && practice.addMedicalCardNumber && <div className="u-marg-bottom">
                                        <label className="c-label" htmlFor="medicalCardNum">Medical Card Number</label>
                                        <input type="text" className="c-input" id="medicalCardNum" name="medicalCardNum"
                                            defaultValue={booking?.medicalCardNum} ref={
                                                register({
                                                    validate: value => !value.match(/[^A-Za-z0-9]/g) || 'Illegal characters detected',
                                                    maxLength : {
                                                        value: 30,
                                                        message: 'Too long, limited to 30 characters' 
                                                      },
                                                })}
                                        />
                                        <p className="u-negative u-milli">{errors?.medicalCardNum?.message}</p>
                                    </div>}
                                    {practice && practice.addPPSNumber && <div className="u-marg-bottom">
                                        <label className="c-label" htmlFor="ppsNum">PPS Number</label>
                                        <input type="text" className="c-input" id="ppsNum" name="ppsNum"
                                            defaultValue={booking?.ppsNum} ref={
                                                register({
                                                    validate: value => !value.match(/[^A-Za-z0-9]/g) || 'Illegal characters detected',
                                                    maxLength : {
                                                        value: 30,
                                                        message: 'Too long, limited to 30 characters' 
                                                      },
                                                })}
                                        />
                                        <p className="u-negative u-milli">{errors?.ppsNum?.message}</p>
                                    </div>}
                                    {practice && practice.addCustomerEmail && <div className="u-marg-bottom">
                                        <label className="c-label" htmlFor="customerEmail">Email Address</label>
                                        {practice && practice.emailRequired ? (
                                            <input type="text" className={errors.email ? inputClass + ' +error' : inputClass} id="email"
                                                   name="email"
                                                   defaultValue={booking?.email}
                                                   ref={register({
                                                       required: { value: true, message: 'Email is required.' },
                                                       pattern: {
                                                           value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                           message: "invalid email address"
                                                       },
                                                   })}
                                            />
                                        ) : (
                                            <input type="text" className="c-input" id="email"
                                                   name="email"
                                                   defaultValue={booking?.email}
                                                   ref={register({
                                                       pattern: {
                                                           value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                           message: "invalid email address"
                                                       },
                                                   })}
                                            />
                                        )}
                                        <p className="u-negative u-milli">{errors?.email?.message}</p>
                                    </div>}
                                    <button className="c-button +large +ghost u-marg-right o-flex__item" role="button" onClick={() => changeStep && changeStep(CALENDAR_STEP)} >Back</button>
                                    <input type="submit" className="c-button +large +primary o-flex__item" value={getNextStep() == BOOKING_COMPLETE_STEP ? "Book Appointment" : "Next"}/>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GatherDetailsStep
