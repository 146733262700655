import IPatientService from "./IPatientService";
import IPatientEssentials from "shared/Practices/Entities/IPatientEssentials"
import { checkExistingPatient } from '../api/PatientAPI'

class PatientService implements IPatientService {
    async checkExistingPatient(patient: IPatientEssentials) {
        const res = await checkExistingPatient(patient);
        return res.data.existingPatient;
    }
}

export default PatientService