import depositsAPI from "../api/DepositsAPI";
import IDepositService from "./IDepositService";

class DepositService implements IDepositService {
  async createDeposit(data) {
    const createDeposit = await depositsAPI.createDeposit(data);
    return createDeposit;
  }
}

export default DepositService;