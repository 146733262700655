import axios from 'axios';
import querystring from 'querystring'
import {TinyEmitter as EventEmitter} from 'tiny-emitter';

const subdomain = window.location.hostname.split('.')[0];
var url = new URL(process.env.WEB_SERVER_PROTOCOL+'://'+process.env.WEB_SERVER_HOST);
url.port = process.env.API_PORT && process.env.API_PORT !== "80" && process.env.API_PORT !== "8080" ? process.env.API_PORT : "";
url.pathname = "/api/v1";
const API_BASE = url;
export const apiEventEmitter = new EventEmitter();
export const API_EVENT_UNAUTHORIZED = 'unauthorized';

if(!localStorage.getItem("sessionId")) {
    const uniqid = require("uniqid");
    localStorage.setItem("sessionId",uniqid());
}

const sessionId = localStorage.getItem("sessionId")

export const getRequest = async <T>(endpoint: string, params: any = {}) => 
  await axios.get<T>(
    `${API_BASE}${endpoint}${
      '?' + querystring.stringify({...params,sessionId,subdomain})
    }`,
  ).then(res => res.data );


export const listRequest = async <T>(endpoint: string, params: any = {}) => 
  await axios.get<T>(
    `${API_BASE}${endpoint}${
      '?' + querystring.stringify({...params,sessionId,subdomain})
    }`,
  ).then(res => res );


export const postRequest = async <T>(endpoint: string, data?: any) => 
  await axios.post<T>(`${API_BASE}${endpoint}`+'?' + querystring.stringify({subdomain}), {...data,sessionId});


export const putRequest = async <T>(endpoint: string, data?: any) => 
  await axios.put<T>(`${API_BASE}${endpoint}`+'?' + querystring.stringify({subdomain}), {...data,sessionId});


export const postRequestNoSessionID = async <T>(endpoint: string, data?: any) => 
  await axios.post<T>(`${API_BASE}${endpoint}`+'?' + querystring.stringify({subdomain}), {...data});

export const putRequestNoSessionID = async <T>(endpoint: string, data?: any) => 
  await axios.put<T>(`${API_BASE}${endpoint}`+'?' + querystring.stringify({subdomain}), {...data});
