import { useState, useContext } from "react";
import ISlotService from "../services/ISlotService";
import { container } from "tsyringe";
import { FatalErrorContext } from "../contexts/FatalErrorContext";
import IAppointmentType from "../components/calendar/IAppointmentType";

const useAppointmentTypes = () => {
  const { fatalErrorSet } = useContext(FatalErrorContext);

  const slotService = container.resolve<ISlotService>("ISlotService");

  const getAppointmentTypes = async () => {
    const appointmentTypes = await slotService
      .getAvailableAppointmentTypes()
      .catch((error) => {
          fatalErrorSet({
            statusCode: error?.response?.status || 500,
            message: error?.response.data?.message || "",
          });
        }
      );
    setAppointmentTypes(appointmentTypes);
  };

  let [appointmentTypes, setAppointmentTypes] = useState<{appointmentTypes: IAppointmentType[]}>();

  return { appointmentTypes, getAppointmentTypes };
};

export default useAppointmentTypes;
