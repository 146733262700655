import React from 'react'
import IStepProps from './IStepProps'
import { useForm } from 'react-hook-form'
import IBooking from 'shared/Practices/Entities/IBooking'
import DateOfBirthSelect from '../DateofBirthSelector'
import {PATIENT_DETAILS_STEP, CHOOSE_REASON_STEP, CALENDAR_STEP} from '../steps/Steps'
import usePatientType from '../../hooks/usePatientType'
import { useAsyncAction } from 'shared/Hooks/useAsyncAction'
import { useState } from 'react'

const PatientDetailsStep = ({changeStep, submitData, setProgressState, booking}: IStepProps) => {

    const { register, setValue, handleSubmit, errors } = useForm<IBooking>();

    let inputClass = 'c-input';

    const [showExistingPatient,setShowExistingPatient] = useState(false);

    const {checkExistingPatient} = usePatientType();
    const errorThrown = false;
    const checkExistingPatientAction = useAsyncAction();
    
    const onSubmit = data => {

        const {
            year,
            month,
            day
        } = data
        
        let dob = new Date(year,month-1,day);
        dob.setTime(dob.getTime() - dob.getTimezoneOffset()*60*1000 );

        const today = new Date();
        
        if(dob > today) {
            alert("Date of birth cannot be in the future.")
            return
        }

        data.dob = dob;
        
        console.log('data',data)
        checkExistingPatientAction.trigger(()=>{
            checkExistingPatient(data).then((existing)=> {
                let dataWithExistingPatientDob = {...data, existing};
                submitData && submitData(dataWithExistingPatientDob);
                changeStep && changeStep(CHOOSE_REASON_STEP);
            })
        })
    };

    setProgressState && setProgressState(PATIENT_DETAILS_STEP);

    return (
        <div className="o-wrap +no-pad@m- c-main +fixed-header">
            <div className="u-pull-v-center@m">
                <div className="u-pad">
                    {Object.keys(errors).length !== 0 && <div className="c-alert +error">Some information was incorrect. Have you filled in all your information correctly?</div>}
                    { checkExistingPatientAction.state.triggered &&
                        <div className="c-alert">Processing patient details</div>
                    }
                    <p className="u-micro u-bold u-uppercase u-lighten">Book your appointment</p>
                    <div className="u-marg-bottom-x2">
                        <h1 className="u-delta u-marg-bottom-half">Patient Details</h1>
                    </div>

                    {!showExistingPatient && 
                        <React.Fragment>
                            <p><span className="u-lighten">If you are a</span> <strong>NEW</strong> <span className="u-lighten">patient click here to proceed.</span></p>        
                            <button onClick={() => changeStep && changeStep(CHOOSE_REASON_STEP)} className="u-marg-right-x2 u-marg-bottom-x2 c-button +large +primary">I am a new patient</button>
                        </React.Fragment>
                    }
                    
                    {!showExistingPatient && <div className="u-marg-bottom-x2">
                        <p><span className="u-lighten">If you are an</span> <strong>EXISTING</strong> <span className="u-lighten">patient click here to proceed.</span></p>
                        <button onClick={() => setShowExistingPatient(true) } className="u-marg-right-x2 u-marg-bottom-x2 c-button +large +primary">I am an existing patient</button>
                    </div>}

                    {showExistingPatient && <div className="u-marg-bottom-x2">
                        <form className="c-form" onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>
                                <div className="o-flex +flex-start u-marg-bottom">
                                    <div>
                                        <label className="c-label" htmlFor="surname">Surname</label>
                                        <input type="text" className={errors.surname ? inputClass + ' +error' : inputClass} id="surname" name="surname" ref={
                                            register({
                                                required: { value: true, message: 'Surname is required.' },
                                                maxLength : {
                                                    value: 300,
                                                    message: 'Too long, limited to 300 characters' 
                                                },
                                                validate: value => !value.match(/[^A-Za-zÀ-ÖØ-öø-ÿ\-]/g) || 'Illegal characters detected'
                                            })} 
                                            defaultValue={booking?.surname}
                                        />
                                        <p className="u-negative u-milli">{errors?.surname?.message}</p>
                                    </div>
                                    <div className="u-marg-left">
                                        <DateOfBirthSelect register={register} errors={errors} inputClass={inputClass} booking={booking}/>
                                    </div>
                                </div>
                                <div className="u-2/3@m u-1 u-marg-bottom">
                                    <label className="c-label" htmlFor="mobileNumber">Mobile Number</label>
                                    <input type="text" className={errors.mobile ? inputClass + ' +error' : inputClass} id="mobileNumber" name="mobile" ref={
                                        register({
                                            required: { value: true, message: 'Mobile is required.' },
                                            maxLength : {
                                                value: 30,
                                                message: 'Too long, limited to 30 characters' 
                                            }
                                        })} 
                                        defaultValue={booking?.mobile}
                                    />
                                    <p className="u-negative u-milli">{errors?.mobile?.message}</p> 
                                </div>
                                <a href="" className="c-button +large +ghost u-marg-right">Cancel</a>
                                <input type="submit" className="c-button +large +primary o-flex__item" value="Next" disabled={checkExistingPatientAction.state.triggered}/>
                            </fieldset>
                        </form>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default PatientDetailsStep;
