import {container} from "tsyringe";
import ISlotService from "../services/ISlotService";
import IBooking from "shared/Practices/Entities/IBooking";
import { useContext } from "react";
import { FatalErrorContext } from "../contexts/FatalErrorContext";

const useSlotBooking = () => {

  const slotService = container.resolve<ISlotService>('ISlotService');
  const { fatalErrorSet } = useContext(FatalErrorContext);
  
  const bookSlot = async (booking:IBooking) => {
    return await slotService.bookSlotForPatient(booking).then((slotBooked)=> {
      return slotBooked;
    }).catch((error)=>{
      fatalErrorSet({
        statusCode: error?.response?.status || 500,
        message: error?.response.data?.message || "",
      });
    });
  };

  return {bookSlot}
};

export default useSlotBooking;