import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CreatePaymentMethodData } from "@stripe/stripe-js";
import React from "react";
import { useState } from "react";
import useDeposit from "../../hooks/useDeposit";
import {BOOKING_COMPLETE_STEP, CALENDAR_STEP, GATHER_DETAILS_STEP, CAPTURED_DEPOSIT_STEP} from "./Steps";

const CapturedDepositStep = (props: any) => {

    const { setProgressState } = props;
    setProgressState && setProgressState(CAPTURED_DEPOSIT_STEP);

    
    const [booking] = useState(props.booking);
    const [practice] = useState(props.practice);
    const [currencySymbol] = useState(props.practice.region === 'Northern Ireland' ? '£' : '€');
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);
    
    const {createDeposit} = useDeposit();

    const stripe = useStripe();
    const elements = useElements();

    const handleCardDetailsChange = event => {
        event.error ? setError(event.error.message) : setError('');
        setProcessing(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            setError('Something went wrong while communicating with the payment provider. Please try again');
            setProcessing(false);
            return;
        }

        setProcessing(true);

        const cardElement = elements.getElement(CardNumberElement)

        if (cardElement) {
            const paymentMethodData: CreatePaymentMethodData = {
                type: "card",
                card: cardElement,
                billing_details: {
                    name: booking.forename + ' ' + booking.surname,
                }
            }
            
            const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodData);
            if (paymentMethodResult.error) {
                setError('Something went wrong while processing your payment details. Please try again');
                setProcessing(false);
                return;
            }

            const { paymentMethod } = paymentMethodResult;

            let regionCurrency;
            if (props.practice.region === "Northern Ireland") {
                regionCurrency = "gbp"
            } else if (props.practice.region === "Republic of Ireland") {
                regionCurrency = "eur"
            }

            const depositData = {
                depositAmount: booking.appointmentDeposit,
                currency: regionCurrency,
                metadata: {integration_check: 'accept_a_payment'},
                payment_method: paymentMethod?.id,
            }
            const depositResult = await createDeposit(depositData);
            const { clientSecret } = depositResult.data;


            await stripe.confirmCardPayment(clientSecret).then((paymentResult) => {
                    if (paymentResult.error) {
                        setError(paymentResult.error.message || 'Something went wrong while processing your payment. Please try again');
                    } else {
                        onSuccess({ paymentToken: paymentResult?.paymentIntent?.id || '' });
                        props.changeStep && props.changeStep(BOOKING_COMPLETE_STEP);
                        setProcessing(false);
                    }
                });
        }
    
    }

    const onSuccess = data => {
        booking.paymentToken = data.paymentToken;
        props.submitData && props.submitData(booking);
    };

    const prevStep = () => {
        if(booking) {
            if(booking.patientId) {
                return props.changeStep && props.changeStep(CALENDAR_STEP);
            } else {
                return props.changeStep && props.changeStep(GATHER_DETAILS_STEP);
            }
        }
    };


    return (
        <div className="o-wrap +no-pad@m- c-main +fixed-header">
            <div className="u-pull-v-centre@m">
                <div className="u-pad">

                    <div className="u-marg-bottom-x2">
                        <h1 className="u-delta u-marg-bottom-half">Deposit Details</h1>
                    </div>

                    <div className="u-marg-bottom-x2">
                        <p>{practice.immediateDepositText}</p>
                    </div>
                    <div className="u-marg-bottom-x2" style={{ paddingBottom: "100px" }}>
                        <p>By ticking this box I am confirming I have read the terms and conditions and I authorise {practice.name} to send instructions to the financial
                            institution that issued my card to take a payment of <strong>{currencySymbol}{booking.appointmentDeposit}</strong> from my card account
                            in accordance with the terms of my agreement with you.
                        </p>
                        <div>
                    <input type="checkbox" name="checkbox" onChange={e => {setTermsAgreed(!termsAgreed);}}/>
                            <label className="u-marg-left u-alpha">I agree to the terms and conditions</label>
                </div>
                <form onSubmit={handleSubmit} className="c-form +fill u-pad-top-bottom">

                    <div className="c-info">
                    <label className="c-label">
                        Card Number</label>
                    <CardNumberElement onChange={handleCardDetailsChange} />
                    </div>
                

                    <div className="o-flex">
                        <div style={{flex: '1'}}>
                    <label className="c-label">
                        Expiration</label>
                    <CardExpiryElement onChange={handleCardDetailsChange} />
                    </div>
                
                    <div style={{flex: '1'}}>
                    <label className="c-label">
                        CVC</label>
                        <CardCvcElement onChange={handleCardDetailsChange} />
                    </div>                   
                    </div>
                    <div className="u-marg-top-bottom">
                    {error && <span><p className="u-negative u-milli">{error}</p></span>}
                    </div>
                    <div className="c-footer">
                        <button type="button" className="c-button +large +ghost u-marg-right o-flex__item" role="button" onClick={() => prevStep()}>Back</button>
                        <button className="c-button +large +primary o-flex__item" disabled={!termsAgreed || processing}>Place Deposit</button>
                    </div>
                </form>
            </div>
        </div>
            </div>
        </div>
    )

}

export default CapturedDepositStep;