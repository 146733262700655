import {useContext, useState} from "react";
import {ISlot} from "shared/Practices/Entities/ISlot";
import {Dayjs} from "dayjs";
import dayjs from "dayjs";
import ISlotService from "../services/ISlotService";
import {container} from "tsyringe";
import IBooking from "shared/Practices/Entities/IBooking";
import { FatalErrorContext } from "../contexts/FatalErrorContext";

const useAvailableSlots = ({practitioner,appointmentType}: IBooking) => {

  const slotService = container.resolve<ISlotService>('ISlotService');

  const initialSlots: ISlot[] = [];

  let [startDateOfMonth, setStartDateOfMonth] = useState(dayjs().date(1));
  let [slots, setSlots] = useState(initialSlots);
  let [slotsLoading, setSlotsLoading] = useState(false);

  const { fatalErrorSet } = useContext(FatalErrorContext);
  
  const setMonthBeginning = async (firstDateOfMonth: Dayjs) => {
    setSlotsLoading(true);
    const slots = await slotService.getAvailableSlotsForDate(firstDateOfMonth,appointmentType,practitioner).then( function (slots) {
          setSlots(slots);
          setSlotsLoading(false);
          setStartDateOfMonth(firstDateOfMonth);
    }).catch(function (error) {
      fatalErrorSet({
        statusCode: error?.response?.status || 500,
        message: error?.response.data?.message || "",
      });
    })

  };

  return {slots, slotsLoading, startDateOfMonth, setMonthBeginning}
};

export default useAvailableSlots;