import ProgressBar from './progressBar/ProgressBar';
import React, {useContext} from 'react';
import { PracticeContext } from '../contexts/PracticeContext';
import logo from '../../../shared/images/booking-logo.svg';
import { FatalErrorContext } from '../contexts/FatalErrorContext';

const Header = (props: any) => {
    const { practice } = useContext(PracticeContext);

    const { progressState } = props
    
    let rgbValue = '0,0,0';
    if (practice && practice.brandingColor) {
        rgbValue = parseInt(practice.brandingColor.substr(1,2), 16) + ',' + parseInt(practice.brandingColor.substr(3,2), 16) + ',' + parseInt(practice.brandingColor.substr(5,2), 16);
    }

    //Set styling based on practice branding
    return (
        <div style={{height: '100px'}}>
            <style dangerouslySetInnerHTML={{
                __html:
                    `:root {--primary-color-rgb:${rgbValue};--primary-color: rgb(var(--primary-color-rgb));--progress-width:10%;}` +
                    ".u-primary {color:var(--primary-color);}" +
                    ".c-progress__bar-filled {background:var(--primary-color);}" +
                    ".c-loader__cube:before{background:var(--primary-color) !important;}" +
                    ".c-button.\\+primary{background:var(--primary-color);}" +
                    ".react-calendar__tile--active{background:var(--primary-color);}" +
                    ".c-radio input:checked ~ .c-radio__checkmark{background:var(--primary-color) !important;}" +
                    ".c-calendar__item.active {color:var(--primary-color);}" +
                    ".c-calendar__item:before{background:var(--primary-color);}" +
                    ".c-radio:hover{background-color:rgba(var(--primary-color-rgb), 0.02);}" +
                    ".react-calendar__tile:enabled:focus{background:var(--primary-color);color:white;}" +
                    ".c-radio:hover input ~ .c-radio__checkmark{background:rgba(var(--primary-color-rgb), 0.15);}" +
                    ".c-calendar__load-button{color:var(--primary-color);}" +
                    ".react-calendar__tile.has-notification:before {background:var(--primary-color);}"
            }} />
            <div className="c-loader-container">
                <div className="c-loader">
                    <div className="c-loader__cube1 c-loader__cube"></div>
                    <div className="c-loader__cube2 c-loader__cube"></div>
                    <div className="c-loader__cube4 c-loader__cube"></div>
                    <div className="c-loader__cube3 c-loader__cube"></div>
                </div>
            </div>
            <div className="c-header">
                <div className="c-header__inner">
                    <div className="o-flex +align-middle +flex-start">
                        <img src={!practice?.logo ? logo : practice.logo} alt="" className="u-block u-marg-right-x1/2" style={{maxHeight: '50px'}}/>
                        <p className="u-primary">{practice.name}</p>
                    </div>
                </div>
                <ProgressBar progressState={progressState}/>
            </div>
        </div>
    )
};

export default Header
