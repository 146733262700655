import {useState} from 'react';

/**
 * useAsyncAction hook returns a structure allowing you to track the state of an async and
 * it's return value.
 *
 * Usage:
 *
 * Invoke the hook and destructure it's return value (or keep it as an object if you prefer).
 * The returned object has 4 main properties:
 *
 * result:  The value of the resolved promise
 * state:   An instance of ActionState to track it's current status
 * trigger: A function you call when you want to invoke the async function. Simply pass
 *          the function to trigger. It will run it and update the status and result
 *          properties as required.
 * error:   If an error occurs is should be present in this property. It is not treated
 *          in anyway so it's type must be determined
 *
 * e.g.
 *
 * /// Triggering
 * const saveContactAction = useAsyncAction();
 *
 * saveContactAction.trigger(() => {
 *   return service.reallySlowMethod();
 * };
 *
 * /// Using status
 * return <div>
 *   {saveContactAction.state.slow && <p>Still processing!</p>}
 *   {saveContactAction.state.complete && <p>{saveContactAction.result}</p>}
 *   </div>
 *
 * Statuses:
 *
 * The state property tracks 4 main lifecycle booleans:
 *
 * triggered:  The action has been triggered. You might use this to prevent double click prevention
 *             by hiding the button in the UI
 * slow:       If the action is taking longer than a present time slow is flipped to true. This is
 *             used to show spinners or show processing messages.
 * complete:   True if the action has finished successfully.
 * failed:     True if the action threw some sort of error - which can be retrieved through the
 *             error property
 *
 * Debouncing:
 *
 * By default there is a 200ms delay before slow is set to true. This avoids 'flickering'
 * in spinners where the action is actually very fast. In those cases the 'flicker' often
 * leads the user to think the action failed, or in some cases aren't sure what they saw
 * and assume something has gone wrong.
 *
 * You can change this value by passing a different integer to the hook function. Zero removes
 * debouncing completely.
 *
 * // Wait longer
 * const saveContactAction = useAsyncAction(500);
 *
 * // Don't wait at all!
 * const saveContactAction = useAsyncAction(0);
 *
 * @param debounceThreshold The number of milliseconds to wait before triggering the slow
 * state. Set to zero for no debounce.
 */
export const useAsyncAction = (debounceThreshold = 200) => {
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [actionState, setActionState] = useState(new ActionState(ActionStates.NotTriggered));

  return {
    result,
    state: actionState,
    error,
    trigger: async(action: any) =>
    {
      setActionState(new ActionState(ActionStates.Triggered));
      setError(null);

      let debounceTimer;

      if (debounceThreshold > 0){
        debounceTimer = setTimeout(() => {
          setActionState(new ActionState(ActionStates.Slow));
        }, debounceThreshold);
      } else {
        setActionState(new ActionState(ActionStates.Slow));
      }

      try {
        /// @ts-ignore
        setResult(await action());
        console.log('action done');
        setActionState(new ActionState(ActionStates.Complete));
        console.log('action complete');
      } catch (err) {
        setActionState(new ActionState(ActionStates.Failed));
        setError(err);
      } finally {
        if (debounceTimer){
          clearTimeout(debounceTimer);
        }
      }
    }
  }
};

export enum ActionStates {
  NotTriggered,
  Triggered,
  Slow,
  Complete,
  Failed
}

/**
 * The ActionState class simply represents our state data structure.
 *
 * It essentially translates a 'point in time' status (passed in the constructor)
 * into 3 booleans. This makes is easy to test for the various statuses without
 * comparing a 'state' variable to constants or even an enum.
 */
class ActionState {
  public state: ActionStates;
  public slow = false;
  public triggered = false;
  public complete = false;
  public failed = false;

  constructor(state: ActionStates) {
    this.state = state;
    this.slow = state == ActionStates.Slow;
    this.triggered = state == ActionStates.Triggered || state == ActionStates.Slow;
    this.complete = state == ActionStates.Complete;
    this.failed = state == ActionStates.Failed;
  }
}