import React, {useCallback, useEffect, useState, useContext} from 'react';
import Calendar from '../calendar/Calendar';
import {default as ReactCalendar} from 'react-calendar';
import DateSelector from "../DateSelector/DateSelector";
import dayjs, { Dayjs } from "dayjs";
import useAvailableSlots from "../../hooks/useAvailableSlots";
import IStepProps from './IStepProps';
import { useAsyncAction } from 'shared/Hooks/useAsyncAction';
import useLockSlot from '../../hooks/UseLockSlot';
import {CHOOSE_REASON_STEP, CALENDAR_STEP, GATHER_DETAILS_STEP, TEXT_VERIFICATION_STEP, BOOKING_COMPLETE_STEP, CAPTURED_DEPOSIT_STEP, DEPOSIT_STEP} from './Steps'
import { PracticeContext } from '../../contexts/PracticeContext';

// There are two different calendars in this component. The sly scroll jquery plugin (DateSelector)
// & the react-calendar (v2) npm plugin (ReactCalendar). Any change of date or month in one must be
// updated in the other. The sly scroll DateSelector is for mobile use.

const CalendarStep = (props: IStepProps) => {

    const { setProgressState } = props
    setProgressState && setProgressState(CALENDAR_STEP);

    const { practice } = useContext(PracticeContext);

    let {slots, slotsLoading, startDateOfMonth, setMonthBeginning} = useAvailableSlots(props.booking!);
    let {lockSlot} = useLockSlot();
    let [showSlotLockedError, setShowSlotLockedError] = useState(false);
    let today = dayjs();
    let [calendarLimit, setCalendarLimit] = useState(1 === 1 ? dayjs().add(12, "month").toDate() : dayjs().add(6, "month").toDate());
    let [selectedDate, setSelectedDate] = useState(dayjs());
    let [selectedSlot, setSelectedSlot] = useState(
        {
            practitioner: '',
            slotDate: new Date(),
            slotType: '',
            slotId: '',
            privateOnly: false
        }
    );

    const getSlotsForMonthAction = useAsyncAction();
    const getSlotsForMonth= (date) => {
        getSlotsForMonthAction.trigger(()=>{
            setMonthBeginning(date);
        });
    };

    const lockSlotAction = useAsyncAction();
    const attemptToSelectSlot = () => {
        lockSlotAction.trigger(() => {
        lockSlot(selectedSlot.slotId)
            .then((success)=>{
                if(success && success.data.slotLocked) {
                    setSelectedSlot(selectedSlot);
                    if (selectedSlot.slotId != '') {
                        props.submitData && props.submitData({slotId: selectedSlot.slotId})
                        props.updateSelectedSlot && props.updateSelectedSlot(selectedSlot);
                        if( !props.booking?.patientId) {
                            props.changeStep && props.changeStep(GATHER_DETAILS_STEP)
                        } else if (practice.textService && props.booking) {
                            props.changeStep && props.changeStep(TEXT_VERIFICATION_STEP)
                        } else if (practice.depositService && props.booking && props.booking?.appointmentDeposit !== 0) {
                            props.changeStep && props.changeStep(CAPTURED_DEPOSIT_STEP)
                        } else if (practice.paymentService) {
                            props.changeStep && props.changeStep(DEPOSIT_STEP)
                        } else {
                            props.changeStep && props.changeStep(BOOKING_COMPLETE_STEP)
                        }
                    }
                } else {
                    setShowSlotLockedError(true);
                }
            })
        });
    }
    
    // Hook only concerned with changes to the practice
    useEffect(() => {
        if (practice) {
            setCalendarLimit(practice.yearSlotLimit ? dayjs().add(1, "year").toDate() : dayjs().add(6, "month").toDate())
            getSlotsForMonth(dayjs().date(1));
        }
    }, [practice]);

    const nextMonth = useCallback(() => {
        const newDate = startDateOfMonth.add(1,'month');
        getSlotsForMonth(newDate);
        setSelectedDate(newDate);
        // Suppressed eslint warning as we intentionally don't want this to depend on anything
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateOfMonth, getSlotsForMonth]);

    const previousMonth = useCallback(() => {
        const newDate = startDateOfMonth.subtract(1,'month');
        getSlotsForMonth(newDate);
        setSelectedDate(newDate);
    }, [startDateOfMonth, getSlotsForMonth]);

    const setCalendarDate = useCallback((value) => {
        setSelectedDate(dayjs(value));
        // reset selectedSlot value
        setSelectedSlot({
            practitioner: "",
            slotDate: new Date(0),
            slotType: "",
            slotId: '',
            privateOnly:false
        })
    }, []);

    const setCalendarMonth = useCallback((value) => {
        setMonthBeginning(dayjs(value));
    }, [setMonthBeginning]);

    const setNextPrevMonth = ({activeStartDate, view}) => {
        setCalendarMonth(activeStartDate);
        setSelectedDate(dayjs(activeStartDate));
    };

    const addDateNotification = (dateNotification) => {
        const day = dayjs(dateNotification.date);
        const dayString = dateNotification.date.toDateString();
        // If the date is in the future
        if (day.format('YY/MM/DD').toString() >= today.format('YY/MM/DD').toString()) {
            // We will add a notification alert if there are slots available
            return slots.filter(slot => dayString === slot.slotDate.toDateString()).length ? 'has-notification' : '';    
        }
        // Dates in the past will not get a notification set.
        return '';
    };

    const disableTile = (dateNotification) => {
        if(dateNotification.view == "year") 
        {
            return false;
        }

        let disableBool = true;
        slots.forEach(slot =>
            {
                if (dateNotification.date.toDateString() === slot.slotDate.toDateString()) {
                    disableBool = false;
                }
            }
        );
        return disableBool;
    };

    const changeSelectedSlot = (dateChanged: Dayjs) => {
        setShowSlotLockedError(false);
        let isMounted = false;
        if(!isMounted) {

        // reset selectedSlot value
        setSelectedSlot({
            practitioner: "",
            slotDate: new Date(0),
            slotType: "",
            slotId: '',
            privateOnly: false
        });
        }
        setSelectedDate(dateChanged);
    };

    if (!getSlotsForMonthAction.state.complete) {
        return (
            <div className="o-wrap +no-pad@m- c-main">
                <div className="u-pull-v-center@m">
                    <h1 className="u-delta u-marg-bottom-x2 u-hide@m-">Searching for appointments</h1>
                </div>
            </div>
            )
    }


    return (
        <div className="o-wrap +no-pad@m- c-main +fixed-header">
            <div className="u-pull-v-center@m">
                {showSlotLockedError && <div className="c-alert +error">This slot has been locked by another user.</div>}
                {lockSlotAction.state.triggered && !lockSlotAction.state.complete && <div className="c-alert">Checking slot availability</div>}
                <h1 className="u-delta u-marg-bottom-x2 u-hide@m-">Book your appointment</h1>
                <DateSelector
                    loading={!getSlotsForMonthAction.state.complete}
                    firstDate={startDateOfMonth}
                    value={selectedDate}
                    onDateSelected={changeSelectedSlot}
                    onNextMonth={nextMonth}
                    onPreviousMonth={previousMonth}
                    slots={slots}
                    maxDate={calendarLimit}
                />

                <div className="o-flex +align-middle u-pad-bottom-footer-height">
                    <div className="o-flex__item u-1/2@m u-hide@m- u-marg-right-x2">
                        <div className="u-pos-rel">
                            <div className="c-loader-container">
                                <div className="c-loader">
                                    <div className="c-loader__cube1 c-loader__cube"></div>
                                    <div className="c-loader__cube2 c-loader__cube"></div>
                                    <div className="c-loader__cube4 c-loader__cube"></div>
                                    <div className="c-loader__cube3 c-loader__cube"></div>
                                </div>
                            </div>
                            <ReactCalendar
                                minDate={today.toDate()}      // Sets initial date but also the start date of calendar every time date is picked
                                minDetail={"year"}
                                activeStartDate={startDateOfMonth.toDate()}
                                maxDate={calendarLimit}
                                value={selectedDate.toDate()}
                                onChange={setCalendarDate}
                                onClickMonth={setCalendarMonth}
                                calendarType={"US"}
                                onActiveDateChange={setNextPrevMonth}
                                tileClassName={addDateNotification}
                                tileDisabled={disableTile}
                            />
                        </div>
                    </div>
                    <div className="o-flex__item u-2/3@m u-pos-rel">
                        <div className="u-pad-left-right@m">
                            <Calendar
                                selectedDate={selectedDate}
                                slotType={'General'}             // Needs to be replaced
                                slots={slots}
                                onSlotSelected={setSelectedSlot}
                                selectedSlot={selectedSlot}
                            />
                        </div>
                    </div>
                </div>

                <div className="c-footer o-flex@m- u-z-overlay">
                    {!props.booking?.patientId &&<button className="c-button +large +ghost u-marg-right o-flex__item" role="button"
                            onClick={() => props.changeStep && props.changeStep(CHOOSE_REASON_STEP)}>Back
                    </button>}
                    <button className="c-button +large +primary o-flex__item" disabled={selectedSlot.slotId == ''}
                            style={{background: 'var(--primary-color)'}}
                            onClick={attemptToSelectSlot}
                    >Continue</button>
                </div>
            </div>
        </div>
    );
};

export default CalendarStep;
