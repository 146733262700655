import React, {useEffect, useState} from 'react';
import CardSection from "../Stripe/CardSection";
import {BOOKING_COMPLETE_STEP, CALENDAR_STEP, DEPOSIT_STEP, GATHER_DETAILS_STEP} from "./Steps";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import usePaymentIntent from "../../hooks/usePaymentIntent";

const DepositStep = (props: any) => {

    const { setProgressState } = props;
    setProgressState && setProgressState(DEPOSIT_STEP);

    const [booking] = useState(props.booking);
    const [practice] = useState(props.practice);
    const [currencySymbol] = useState(props.practice.region === 'Northern Ireland' ? '£' : '€');
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const {clientSecretKey, getClientSecretKey} = usePaymentIntent();

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        getClientSecretKey();
    },[]);

    useEffect(() => {
        // @ts-ignore
        setClientSecret(clientSecretKey);
    },[clientSecretKey]);

    const onSuccess = data => {
        booking.paymentToken = data.paymentToken;
        props.submitData && props.submitData(booking);
    };
    
    const prevStep = () => {
        if(booking) {
            if(booking.patientId) {
                return props.changeStep && props.changeStep(CALENDAR_STEP);
            } else {
                return props.changeStep && props.changeStep(GATHER_DETAILS_STEP);
            }
        }
    };

    const handlePayment = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        if (cardElement) {
            // @ts-ignore
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: booking.forename + ' ' + booking.surname
                    }
                }
            });

            if (result.error) {
                alert(result.error.message)
            } else {
                onSuccess({paymentToken: result.setupIntent.payment_method});
                props.changeStep && props.changeStep(BOOKING_COMPLETE_STEP);
            }
        }
    };

    return (
        <div className="o-wrap +no-pad@m- c-main +fixed-header">
            <div className="u-pull-v-center@m">
                <div className="u-pad">

                    <div className="u-marg-bottom-x2">
                        <h1 className="u-delta u-marg-bottom-half">Payment Details</h1>
                    </div>
                    <div className="u-marg-bottom-x2">
                        <p>{practice.tokenisedDepositText}</p>
                    </div>
                    <div className="u-marg-bottom-x2" style={{ paddingBottom: "100px" }}>
                        <p>By ticking this box I am confirming I have read the terms and conditions and I authorise {practice.name} to send instructions to the financial
                            institution that issued my card to take a payment of <strong>{currencySymbol}{practice.paymentAmount}</strong> from my card account
                            in accordance with the terms of my agreement with you.
                        </p>
                        <div>
                            <input type="checkbox" name="checkbox" onChange={e => {setTermsAgreed(!termsAgreed);}}/>
                            <label className="u-marg-left u-alpha">I Agree</label>
                        </div>
                        <form onSubmit={handlePayment}>
                            <CardSection />
                            <div className="c-footer">
                                <button
                                    type="button"
                                    className="c-button +large +ghost u-marg-right o-flex__item"
                                    role="button"
                                    onClick={() => prevStep()}
                                >Back
                                </button>
                                <button className="c-button +large +primary o-flex__item" disabled={!termsAgreed}>
                                    Make Payment
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DepositStep;