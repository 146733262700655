import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import initialBookingState from '../../constants/initialBookingState';
import useSlotBooking from '../../hooks/useSlotBooking';
import IStepProps from './IStepProps'
import {BOOKING_COMPLETE_STEP, CALENDAR_STEP, PATIENT_DETAILS_STEP} from './Steps'

const BookingCompletedStep = ({setProgressState, changeStep, booking, selectedSlot, submitData}: IStepProps) => {

    setProgressState && setProgressState(BOOKING_COMPLETE_STEP);
    let {bookSlot} = useSlotBooking()
    const [bookingComplete, setBookingComplete] = useState(false);
    const [bookingFailed, setBookingFailed] = useState(false);

    const MakeNewBooking = () => {
        submitData && submitData(initialBookingState);
        changeStep && changeStep(PATIENT_DETAILS_STEP);
    }

    // Hook only concerned with changes to the practice
    useEffect(() => {
        bookSlot(booking!).then((slotBooked)=>{
            if(slotBooked) {
                setBookingComplete(true);
            } else {
                setBookingFailed(true);
            }
        })
    }, []);

    if(!bookingComplete && !bookingFailed) {
        return (<p>Processing Booking</p>);
    }

    return (
        <div className="o-wrap +no-pad@m- c-main +fixed-header">
            <div className="u-pull-v-center@m">
                {bookingFailed && 
                    <div className="u-pad">

                        <div className="c-alert +error">
                            We're sorry, but this slot could not be booked.
                        </div>
                        
                        <button className="c-button +large +ghost u-marg-right o-flex__item" onClick={() => changeStep && changeStep(CALENDAR_STEP)}>Pick another slot</button>
                    </div>
                }
                {bookingComplete && 
                <div>
                    <div className="u-pad">
                    <p className="u-micro u-bold u-uppercase u-lighten">Your appointment is booked!</p>
                    <div className="u-marg-bottom-x2">
                        <h1 className="u-delta">Thank You!</h1>
                        <p className="u-lighten">Your appointment has been confirmed.</p>
                        {booking && selectedSlot &&
                            <div>
                                {booking.forename && booking.surname && <p>Patient: {booking.forename} {booking.surname}</p>}
                                {booking.email && booking.email !== '' && <p>Email: {booking.email}</p>}
                                <p>Type: {selectedSlot.slotType}</p>
                                <p>Date: {selectedSlot.slotDate.toLocaleTimeString()} {selectedSlot.slotDate.toDateString()}</p>
                                <p>Practitioner: {selectedSlot.practitioner}</p>
                            </div>
                        }
                    </div>
                    <button className="c-button +large +ghost u-marg-right o-flex__item" onClick={MakeNewBooking}>Make Another Booking</button>
                    </div>
                </div>
                }
                </div>
        </div>
    )
}


export default BookingCompletedStep
