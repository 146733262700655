import IPatientService from "../services/IPatientService"
import {container} from "tsyringe";
import IPatientEssentials from 'shared/Practices/Entities/IPatientEssentials'
import { useContext, useState } from "react";
import { FatalErrorContext } from "../contexts/FatalErrorContext";

const usePatientType = () => {
    const patientService = container.resolve<IPatientService>('IPatientService')
    const { fatalErrorSet } = useContext(FatalErrorContext);

    const checkExistingPatient = async (patient: IPatientEssentials) => {
        return await patientService.checkExistingPatient(patient).then((existing) =>{
            return existing;
        }).catch(function (error) {
            fatalErrorSet({
                statusCode: error?.response?.status || 500,
                message: error?.response.data?.message || "",
              });
        })
    }
    return {checkExistingPatient}
}

export default usePatientType