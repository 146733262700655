import { getRequest } from "../../../shared/API/rest";
import { ENDPOINT_PAYMENT } from "../constants/endpoints";

class PaymentsAPI {

    setupPaymentIntent() {

        return getRequest<any>(ENDPOINT_PAYMENT).then(async (clientSecret) => {
            return clientSecret;
        })
    }
}

const paymentsAPI = new PaymentsAPI();
export default paymentsAPI;