import React from 'react';
import Slots from './Slots';
import ICalendarProps from './ICalendarProps';

const Calendars = (props: ICalendarProps) => {

    return (
        <div>
            <div>
                <Slots
                    slots={props.slots}
                    slotType={props.slotType}
                    selectedDate={props.selectedDate}
                    onSlotSelected={props.onSlotSelected}
                    selectedSlot={props.selectedSlot}
                />
            </div>

        </div>
      );
}
export default Calendars;
