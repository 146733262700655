import React from 'react';
import {ISlot} from "shared/Practices/Entities/ISlot";
import {Dayjs} from 'dayjs';

const Slots = (props: ISlots) => {
    let classNameAttribute = 'c-radio c-module +stacked u-pad-left-right u-border-darken';

    const filteredByDate = props.slots.filter(day =>
        day.slotDate.getDate() === props.selectedDate.date() &&
        day.slotDate.getMonth() === props.selectedDate.month() &&
        day.slotDate.getFullYear() === props.selectedDate.year());
    
    const sortedByStartTime = filteredByDate.sort((leftSide, rightStide): number => {
        if(leftSide.slotDate < rightStide.slotDate) return -1;
        if(leftSide.slotDate > rightStide.slotDate) return 1;
        return 0;
    });

    const addIsSelected = (slot: ISlot) =>{
        if(slot.slotId === props.selectedSlot.slotId) {
            classNameAttribute += ' is-selected'; 
        }
    };

    return (
        <div className="u-overflow-auto c-main__appointment-list">
            {sortedByStartTime.length !== 0 &&
                <p className="u-medium u-milli u-marg-bottom-half u-pad-left-right@m-">Available {props.slotType} Appointments</p>}
            {sortedByStartTime.length === 0 &&
                <div className="u-pad-left-right@m- u-pad-top-bottom u-align-center u-pull-v-center@m +center">
                    <h1 className="u-marg-none u-beta">No appointments found</h1>
                    <p className="u-mill">There are no dentists available today.</p>
                </div>}
            
            {sortedByStartTime.map((slot) => {
                return (
                    <label className={classNameAttribute}  key={slot.slotId} htmlFor={slot.slotId}>
                        <div>
                            <div 
                                onClick={() => {
                                    addIsSelected(slot);
                                    props.onSlotSelected(slot);
                                }}
                                >
                                <div className='dateText u-pad-top-bottom-half'>
                                    <div className="o-flex +align-middle u-pos-rel">
                                        <div>
                                            <p className="u-marg-none u-milli">{slot.practitioner}</p>
                                            <p className="u-beta u-primary">{slot.slotDate.toLocaleTimeString('en-GB',{hour: '2-digit', minute: '2-digit'})}</p>
                                        </div>
                                        <input type="radio" className="c-radio__input" name="radio" value={slot.slotId} id={slot.slotId}/>
                                        <span className="c-radio__checkmark u-border-darken" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                );
            })}
        </div>
    )
}

interface ISlots {
    slots: Array<ISlot>
    slotType: string
    selectedDate: Dayjs
    onSlotSelected?: any
    selectedSlot: ISlot
};

export default Slots;
