import React, { createContext, useState } from "react";

export interface FatalErrorI {
  message: string,
  statusCode: number
}

export interface FatalErrorContextI {
    fatalError?: FatalErrorI;
    fatalErrorSet: (newError: FatalErrorI) => void;
}
export const FatalErrorContext = createContext<FatalErrorContextI>({
  fatalErrorSet: () => {}
} as FatalErrorContextI);

/**
 * We can handle bad api responses and push them back to the header from here.
 * 
 * A nicer way might have been to have an EventEmitter listening straight to the api's, as that way we could write a 
 * generic error handler into rest.ts - however, this is only for fatalErrors so there shouldn't be that many occurences
 * of this getting registered.
 */
export const FatalErrorContextProvider: React.FC<{}> = (
  { children }
) => {
  const value = useFatalErrorContext();
  return (
    <FatalErrorContext.Provider value={value}>
      {children}
    </FatalErrorContext.Provider>
  )
};

const useFatalErrorContext = () => {
  const [fatalError, fatalErrorSet] = useState<FatalErrorI>();

  return {
    fatalError,
    fatalErrorSet
  };
};