import {useContext, useState} from 'react';
import IPaymentService from "../services/IPaymentService";
import { container } from "tsyringe";
import { FatalErrorContext } from '../contexts/FatalErrorContext';


const usePaymentIntent= () => {

        const paymentService = container.resolve<IPaymentService>('IPaymentService');

        const [clientSecretKey, setClientSecretKey] = useState();
        const { fatalErrorSet } = useContext(FatalErrorContext);
        
        const getClientSecretKey = async () => {
            const clientSecretKey = await paymentService.createSetupIntent().then((clientSecretKey) => {
                setClientSecretKey(clientSecretKey);
            }).catch(function (error) {
                fatalErrorSet({
                    statusCode: error?.response?.status || 500,
                    message: error?.response.data?.message || "",
                });
            });
        };
        return {clientSecretKey, getClientSecretKey};
};

export default usePaymentIntent;