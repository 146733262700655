import { container } from "tsyringe";
import IDepositService from "../services/IDepositService";

const useDeposit = () => {

    const depositService = container.resolve<IDepositService>('IDepositService');

    const createDeposit = async (data) => depositService.createDeposit(data);

    return {createDeposit}
};

export default useDeposit;