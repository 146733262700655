import IPatientService from "./IPatientService"
import IBooking from "shared/Practices/Entities/IBooking"
import CheckPatientResponse from "../api/responses/CheckPatientResponse";

class TestPatientService implements IPatientService {
    checkExistingPatient(booking: IBooking): Promise<any> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(true);
            }, 1500);
        });
    }
}

export default TestPatientService