import { ENDPOINT_PATIENT } from '../constants/endpoints'
import { getRequest, postRequest } from "shared/API/rest"
import IPatientEssentials from "shared/Practices/Entities/IPatientEssentials"
import CheckPatientResponse from '../api/responses/CheckPatientResponse'

export const checkExistingPatient = (patient: IPatientEssentials) => {
    return postRequest<CheckPatientResponse>(ENDPOINT_PATIENT, patient).then(
        (r) => {
           console.log("R: ", r) 
           return r;
        }
      );
}

export default checkExistingPatient