import * as React from "react";
import { PracticeContextProvider } from "../contexts/PracticeContext";
import AppWithPractice from "./AppWithPractice";
import { FatalErrorContextProvider } from "../contexts/FatalErrorContext";

const App: React.FC = () => {
  /**
   * All of the app requires knowledge about the current practice, rather than pass that down a lot we've wrapped every
   * part of the app in this context.
   * At <App> level we then should have access to the `practice` as well as any error message that has occurred when we
   * tried to fetch it from the api.
   */
  return (
    <FatalErrorContextProvider>
      <PracticeContextProvider>
        <AppWithPractice />
      </PracticeContextProvider>
    </FatalErrorContextProvider>
  );
};

export default App;
