import ISlotService from './ISlotService';
import {Dayjs} from "dayjs";
import slotsAPI from '../api/SlotsAPI';
import IBooking from 'shared/Practices/Entities/IBooking';

class SlotService implements ISlotService {
    async lockSlot(slotId: string): Promise<any> {
        const success = await slotsAPI.lockSlot(slotId);
        return success;
    }

    async getAvailableSlotsForDate(date: Dayjs,appointmentType:string = "",practitioner:string = "") {
        const slots = await slotsAPI.listSlots(date,appointmentType,practitioner);
        return slots;
    }

    async bookSlotForPatient(booking: IBooking) {
        const result = await slotsAPI.book(booking);
        return result.data.slotBooked;
    }

    async getAvailableAppointmentTypes() {
        const types = await slotsAPI.listTypes();
        return types;
    }
}

export default SlotService;

