import React from 'react';
import "reflect-metadata";
import ReactDOM from 'react-dom';
import App from './components/App';
import '../../shared/css/main.css';
import SlotService from "./services/SlotService";
import {container} from "tsyringe";
import ISlotService from "./services/ISlotService";
import { IPracticeService } from 'shared/Services/Practice/IPracticeService';
import PracticeService from 'shared/Services/Practice/PracticeService';
import {config} from "dotenv";

//Services
import PatientService from "./services/PatientService"

//TestServices
import TestSlotService from "./services/TestSlotService"
import TestPatientService from "./services/TestPatientService"

//ServiceInterfaces
import IPatientService from './services/IPatientService';
import TextVerificationService from './services/TextVerificationService';
import ITextVerificationService from './services/ITextVerificationService';
import IPaymentService from "./services/IPaymentService";
import PaymentService from "./services/PaymentService";
import IDepositService from './services/IDepositService';
import DepositService from './services/DepositService';

config({ path: ".env" });

container.register<ISlotService>('ISlotService', SlotService);
container.registerSingleton<IPracticeService>('IPracticeService', PracticeService);
container.register<IPatientService>('IPatientService', PatientService);
container.register<ITextVerificationService>('ITextVerificationService', TextVerificationService);
container.register<IPaymentService>('IPaymentService', PaymentService);
container.register<IDepositService>('IDepositService', DepositService);

ReactDOM.render(

  <App
    slotsService={new SlotService()}
    patientService={new TestPatientService()}
  />, document.getElementById('root')
);

