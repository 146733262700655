import {container} from "tsyringe";
import ISlotService from "../services/ISlotService";
import { useContext } from "react";
import { FatalErrorContext } from "../contexts/FatalErrorContext";

const useLockSlot = () => {

  const slotService = container.resolve<ISlotService>('ISlotService');
  const { fatalErrorSet } = useContext(FatalErrorContext);
  
  const lockSlot = async (slotId:string) => {
    const success = await slotService.lockSlot(slotId).catch((error)=>{
      fatalErrorSet({
        statusCode: error?.response?.status || 500,
        message: error?.response.data?.message || "",
      });
      return false;
    });
    return success;
  };

  return {lockSlot}
};

export default useLockSlot;