import React from "react";
import logo from "../../../shared/images/booking-logo.svg";
import { useContext } from "react";
import { FatalErrorContext } from "../contexts/FatalErrorContext";
import { PracticeContext } from "../contexts/PracticeContext";

const ErrorHandler = (props: any) => {
  const { fatalError } = useContext(FatalErrorContext);
  const { practice } = useContext(PracticeContext);

  if (fatalError) {
    const statusCode = fatalError.statusCode;
    const message = fatalError.message;

    return (
      <div>
        <div>
          <div className="o-wrap +no-pad@m- c-main +fixed-header">
            <div>
              <div>
                <div className="o-wrap +no-pad@m- +fixed-header">
                  <div>
                    <div className="u-pad">
                      <div className="o-flex +align-middle +flex-start">
                        <img
                          src={!practice?.logo ? logo : practice.logo}
                          alt=""
                          className="u-block u-marg-right-x1/2"
                        />
                      </div>
                      {practice && (
                        <>
                          An error has occurred which has prevented us from
                          completing your booking at this time.
                          <br />
                          <br />
                          {practice.phoneNumber && (
                            <>
                              If the error persists please call
                              <strong> {practice.phoneNumber}</strong> to book
                              your appointment
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // If there's no error set we've nothing to return
  return null;
};

export default ErrorHandler;
