import React from 'react'

const DateOfBirthSelect = ({register, errors, inputClass, booking}) => {    

    const maxYear = new Date().getFullYear();
    const minYear = maxYear - 200;
    const dayRangeError = 'Please enter a number between 1 and 31';
    const monthRangeError = 'Please enter a number between 1 and 12';
    const yearRangeError = `Please enter a year between ${minYear} and ${maxYear}`;

    const handleChange = e => {
        const { maxLength, value, id } = e.target;

        if(value.length > maxLength)
        {
            e.target.value = value.slice(0,maxLength);
        }

        if(value.length >= maxLength)
        {
            switch(id) 
            {
                case "day":
                    document.getElementById('month')?.focus();
                    break;

                case "month":
                    document.getElementById('year')?.focus();
                    break;

                case "year":
                    document.getElementById('mobileNumber')?.focus();
                    break;
            }
        }
    }

    return (
        <div>
            <label className="c-label" htmlFor={"day"}>Date of Birth</label>
            <div className="o-flex +flex-start u-1/2@m">
                <div>
                    <input onChange={handleChange} className={errors.day ? inputClass + ' +error' : inputClass} type="number" id="day" name="day" placeholder="DD" maxLength={2} defaultValue={booking?.day} ref={
                        register({
                            required: { value: true, message: 'Day is required.' },
                            max: { value: 31, message: dayRangeError},
                            min: { value: 1, messsage: dayRangeError}
                        })} ></input>
                    <p className="u-negative u-milli">{errors?.day?.message}</p>
                </div>
                <div>
                    <input onChange={handleChange} className={errors.month ? inputClass + ' +error' : inputClass} type="number" id="month" name="month" placeholder="MM" maxLength={2} defaultValue={booking?.month} ref={
                        register({
                            required: { value: true, message: 'Month is required.' },
                            max: { value: 12, message: monthRangeError},
                            min: { value: 1, messsage: monthRangeError}
                        })} ></input>
                    <p className="u-negative u-milli">{errors?.month?.message}</p>
                </div>
                <div>
                    <input onChange={handleChange} className={errors.year ? inputClass + ' +error' : inputClass} type="number" id="year" name="year" placeholder="YYYY" maxLength={4} defaultValue={booking?.year} ref={
                        register({
                            required: { value: true, message: 'Year is required.' },
                            max: { value: maxYear, message: yearRangeError},
                            min: { value: minYear, messsage: yearRangeError}
                        })} ></input>
                    <p className="u-negative u-milli">{errors?.year?.message}</p>
                </div>
            </div>
        </div>
    )
}

export default DateOfBirthSelect
