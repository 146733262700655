import React, {useEffect} from 'react'
import IStepProps from './IStepProps'
import useAppointmentTypes from '../../hooks/UseAppointmentTypes'
import IBooking from 'shared/Practices/Entities/IBooking'
import { useForm } from 'react-hook-form'
import {useAsyncAction} from "../../../../shared/Hooks/useAsyncAction";
import {PATIENT_DETAILS_STEP, CHOOSE_REASON_STEP, CALENDAR_STEP} from '../steps/Steps'

const ChooseReasonStep = ({changeStep, submitData, setProgressState}: IStepProps) => {

    let {appointmentTypes, getAppointmentTypes} = useAppointmentTypes();
    const { register, handleSubmit } = useForm<IBooking>();

    const  getAppointmentTypesAction = useAsyncAction();

    useEffect(()=>{
        getAppointmentTypesAction.trigger(()=>{
            return getAppointmentTypes();
        });
    },[]);

    const onSubmit = data => {
        console.log('onsubmit', data);
        const type = appointmentTypes?.appointmentTypes.find(type => type.type === data.appointmentType);
        submitData && submitData({
            appointmentType: data.appointmentType,
            appointmentDeposit: type?.deposit ?? 0
        });
        changeStep && changeStep(CALENDAR_STEP)
    };

    setProgressState && setProgressState(CHOOSE_REASON_STEP);

    return (
        <div className="o-wrap +no-pad@m- c-main +fixed-header">
            <div className="u-pull-v-center@m">
                <div className="u-pad">
                    <p className="u-micro u-bold u-uppercase u-lighten">Book your appointment</p>
                    <div className="u-marg-bottom-x2">
                        <h1 className="u-delta u-marg-bottom-half">Reason for appointment</h1>
                        <p className="u-lighten">This will determine what time slots are <br/>available as early as possible.</p>
                    </div>
                    <div className="u-marg-bottom-x2">
                        <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                            <fieldset className="u-marg-bottom">
                                <label className="u-beta u-marg-bottom-half u-block" htmlFor="appointmentType">I require a</label>
                                <select name="appointmentType" id="appointmentType" ref={register}>
                                    {appointmentTypes?.appointmentTypes.map((response, i) => {
                                        return (
                                            <option value={response.type} key={i}>{response.type}</option>
                                        )
                                    })}
                                </select>
                            </fieldset>
                            <button className="c-button +large +ghost u-marg-right o-flex__item" onClick={() => changeStep && changeStep(PATIENT_DETAILS_STEP)}>Back</button>
                            <input
                                type="submit"
                                value="Select a date"
                                className="c-button +large +primary o-flex__item" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ChooseReasonStep
