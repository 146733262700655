import React, {useCallback, useMemo} from 'react';
import SlyScroller from "./SlyScroller";
import {IDateSelectorProps} from "./IDateSelectorProps";
import dayjs from "dayjs";

/**
 * DateSelector wraps the Sly horizontal scrolling jQuery plugin.
 *
 * As it's doing (as far as React is concerned) direct DOM manipulation
 * we have to be careful not to reRender spuriously. Hence you'll see
 * reference to useMemo and useCallback.
 *
 * @param props
 * @constructor
 */
const DateSelector = (props: IDateSelectorProps) => {

    let firstDate = props.firstDate || dayjs().date(1);
    const currentMonth = firstDate.month();
    const value = props.value || dayjs();
    const onNextMonth = props.onNextMonth;
    const onPreviousMonth = props.onPreviousMonth;
    const slotArray = props.slots;
    const maxDate = props.maxDate ? props.maxDate : dayjs().add(1, "year").toDate();

    // Ensure the children are only regenerated when the month changes
    // We ourselves will have to reRender when say the loading property
    // changes, but we don't wan't to cause the SlyScroller to rerender
    // in turn, unless it's properties have changed.
    const items = useMemo(() => {
        let walkDate = firstDate.clone();
        let appointmentDay = false;
        let classNameAttribute = 'day c-calendar__item';
        const today = dayjs();
        const days: JSX.Element[] = [];

        days.push(
            <li key={'previous'} className={'interactive'}>
                <button className="c-calendar__load-button +previous" disabled={walkDate <= dayjs()}
                onClick={() => {
                    if (onPreviousMonth){
                        onPreviousMonth();
                        }  
                    }}>{firstDate.subtract(1, 'month').format('MMM')} 
                </button> 
            </li> 
        ); 
        
        // WalkDate.month is 0 index
        while(currentMonth === walkDate.month()){
            
            // Find days with appointments
            appointmentDay = false;
            classNameAttribute = 'day c-calendar__item'
            for (let loop = 0; loop < slotArray.length; loop++) {

                let day = dayjs(slotArray[loop].slotDate);

                if(walkDate.format('YY/MM/DD') >= today.format('YY/MM/DD') && day.format('YY/MM/DD') === walkDate.format('YY/MM/DD')){
                    appointmentDay = true;
                    break;
                }
            }

            if(props.maxDate) {
                // console.log(parseInt(today.add(6, "month").format('M')));
                if(props.maxDate.getMonth()+1 === parseInt(today.add(6, "month").format('M'))) {
                    // Disable any day 6 months from today
                    walkDate.format('YY/MM/DD') > today.add(6, "month").format('YY/MM/DD') ?  classNameAttribute += ' is-disabled': classNameAttribute += '';
                } else {
                    // Disable any day a year from today
                    walkDate.format('YY/MM/DD') > today.add(1, "year").format('YY/MM/DD') ?  classNameAttribute += ' is-disabled': classNameAttribute += '';
                }
            }

            // Disable any day before today
            walkDate.format('YY/MM/DD') < today.format('YY/MM/DD') ? classNameAttribute += ' is-disabled': classNameAttribute += '';

            // Disable any day without available appointments
            !appointmentDay ? classNameAttribute += ' is-disabled' : classNameAttribute += '';

            // Set initial active date
            walkDate.format('DD/MM/YY').toString() === today.format('DD/MM/YY').toString() ? classNameAttribute += ' active': classNameAttribute += '';

            // Set notifications on dates with available appointments
            appointmentDay ? classNameAttribute += ' has-notification' : classNameAttribute += '';

            days.push(<li key={walkDate.date()} className={classNameAttribute}><p className="c-calendar__day">{walkDate.format("ddd")}</p>
            <p className="c-calendar__date">{walkDate.date()}</p><p className="c-calendar__month">{walkDate.format('MMM')}</p></li>);
            walkDate = walkDate.add(1, 'day');
        }

        days.push(
            <li key={'next'} className={'interactive'}>
                <button className="c-calendar__load-button +next"
                    disabled={walkDate.toDate() >= maxDate}
                    onClick={() => {
                        if (onNextMonth){
                            onNextMonth();
                        }
                    }}>{firstDate.add(1, 'month').format('MMM')}
                </button>
            </li>)
        return days;
    },[firstDate, currentMonth, onNextMonth, onPreviousMonth, slotArray]);

    const startAt = value.month() === firstDate.month() ? value.date(): 1;

    let {onDateSelected} = props;

    // We have to call useCallback to get a single callback instead of generating a new one
    // with each render. This callback is passed to SlyScroller so again it would reRender
    // every time we render if the callback was different each time. Note that we include
    // a dependency of firstDate. That's because the callback itself encloses the firstDate
    // value and is used to raise the event about a date being selected. If this callback
    // was **never** new, then it would always be the first value.
    const onActive = useCallback((day) => {
        const date = firstDate.month(firstDate.month()).date(day);
        if (onDateSelected){
            onDateSelected(date);
        }
    }, [firstDate, onDateSelected]);

    return useMemo(() => (<div className={props.loading ? 'loading' : ''}>
        <SlyScroller options={{
            horizontal: true,
            itemNav: 'centered',
            mouseDragging: true,
            touchDragging: true,
            elasticBounds: true,
            releaseSwing: true,
            speed: 500,
            smart: true,
            activateOn: 'click',
            interactive: 'li.interactive',
            startAt,
        }} onActive={onActive}>
            {items}
        </SlyScroller>
        
    </div>), [firstDate]);
};

export default DateSelector;
