import React, { useState, useEffect, useContext } from 'react';
import PatientDetailsStep from './steps/PatientDetailsStep';
import ChooseReasonStep from './steps/ChooseReasonStep';
import CalendarStep from './steps/CalendarStep';
import Wizard from './wizard/Wizard';
import IBooking from 'shared/Practices/Entities/IBooking';
import TextVerificationStep from './steps/TextVerificationStep';
import GatherDetailsStep from './steps/GatherDetailsStep';
import initialBookingState from '../constants/initialBookingState'
import BookingCompletedStep from './steps/BookingCompletedStep';
// import IPatient from './IPatient';
import * as Steps from './steps/Steps'
import { ISlot } from 'shared/Practices/Entities/ISlot';
import { PracticeContext } from '../contexts/PracticeContext';
import Step from './steps/Step';
import CapturedDepositStep from "./steps/CapturedDepositStep";
import DepositStep from "./steps/DepositStep";

const MainForm = ({setProgressState})=> {
    const [bookingState, setBookingState] = useState<IBooking>(initialBookingState);
    const [defaultStep, setDefaultStep] = useState<string>();
    const [selectedSlot, setSelectedSlot] = useState<ISlot>();
    const { practice } = useContext(PracticeContext);

    const queryString = require('query-string');

    useEffect(() => {
        const queryData = queryString.parse(location.search);
        if(queryData.patientId) {
            setBookingState({...bookingState,...queryData});
            setDefaultStep(Steps.CALENDAR_STEP);
        } else {
            setDefaultStep(Steps.PATIENT_DETAILS_STEP);
        }
    }, []);

    const pushToState = (data) => {
        setBookingState({...bookingState, ...data})
    };

    if(!defaultStep) {
        return <p>Loading</p>
    }
    
    const updateSelectedSlot = (slot:ISlot) => {
        setSelectedSlot(slot);
    }

    return (
        <Wizard defaultStep={defaultStep}>
            <Wizard.Step name={Steps.PATIENT_DETAILS_STEP}>
                <PatientDetailsStep 
                    booking={bookingState}
                    submitData={pushToState} 
                    setProgressState={setProgressState}
                />                    
            </Wizard.Step>
            <Wizard.Step name={Steps.CHOOSE_REASON_STEP}>
                <ChooseReasonStep 
                    submitData={pushToState} 
                    setProgressState={setProgressState}
                />
            </Wizard.Step>
            <Wizard.Step name={Steps.CALENDAR_STEP}>
                <CalendarStep 
                    submitData={pushToState} 
                    booking={bookingState}
                    updateSelectedSlot={updateSelectedSlot}
                    setProgressState={setProgressState}
                />
            </Wizard.Step>
            <Wizard.Step name={Steps.GATHER_DETAILS_STEP}>
                <GatherDetailsStep 
                    submitData={pushToState} 
                    booking={bookingState} 
                    setProgressState={setProgressState}
                />
            </Wizard.Step>
            <Wizard.Step name={Steps.TEXT_VERIFICATION_STEP} >
                <TextVerificationStep 
                    submitData={pushToState} 
                    booking={bookingState} 
                    practice={practice} 
                    setProgressState={setProgressState}
                />
            </Wizard.Step>
            <Wizard.Step name={Steps.CAPTURED_DEPOSIT_STEP} >
                <CapturedDepositStep
                    submitData={pushToState}
                    booking={bookingState}
                    practice={practice}
                    setProgressState={setProgressState}
                />
            </Wizard.Step>
            <Wizard.Step name={Steps.DEPOSIT_STEP} >
                <DepositStep
                    submitData={pushToState} 
                    booking={bookingState} 
                    practice={practice} 
                    setProgressState={setProgressState}
                />
            </Wizard.Step>
            <Wizard.Step name={Steps.BOOKING_COMPLETE_STEP} >
                <BookingCompletedStep 
                    booking={bookingState} 
                    selectedSlot={selectedSlot}
                    setProgressState={setProgressState}
                    submitData={pushToState}
                />
            </Wizard.Step>
        </Wizard>  
    )
}

export default MainForm

    
    
