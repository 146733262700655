import {postRequest} from "shared/API/rest";
import {ENDPOINT_DEPOSITS} from "../constants/endpoints";

class DepositsAPI {
  createDeposit(data) {
    return postRequest<any>(ENDPOINT_DEPOSITS, {...data}).then(async (res) => {
      return res;
    });
  }
}

const depositsAPI = new DepositsAPI();
export default depositsAPI;