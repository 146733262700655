import { useState, useContext } from "react";
import { ITextVerificationDetails } from "shared/Practices/Entities/ITextVerificationDetails";
import {container} from "tsyringe";
import { FatalErrorContext } from "../contexts/FatalErrorContext";
import ITextVerificationService, { ISendVerificationTextResult } from "../services/ITextVerificationService";

const useTextVerification = () => {

  const textVerificationService = container.resolve<ITextVerificationService>('ITextVerificationService');
  const { fatalErrorSet } = useContext(FatalErrorContext);
  
  const sendVerificationText = async (mobile?:string, patientId?:string) => {
    return await textVerificationService.sendVerificationText(mobile,patientId).then((result:ISendVerificationTextResult)=>{
        return result;
    }).catch((error)=>{
      fatalErrorSet({
        statusCode: error?.response?.status || 500,
        message: error?.response.data?.message || "",
      });
    });
  };

  const verifyCode = async(key:string, code:string) => {
      const verified = await textVerificationService.verifyCode(key,code);
      return verified;
  }

  return {sendVerificationText,verifyCode}
};

export default useTextVerification;