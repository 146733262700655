import IPaymentService from "./IPaymentService";
import paymentsAPI from "../api/PaymentsAPI";

class PaymentService implements IPaymentService{

    async createSetupIntent() {
        const setupIntent = await paymentsAPI.setupPaymentIntent();
        return setupIntent;
    }
}

export default PaymentService;