const initialBookingState = {
    forename: '', 
    surname: '', 
    mobile: '', 
    dob: new Date(), 
    existing: false, 
    sex: '', 
    healthcareNum: '', 
    paymentToken: '',
    medicalCardNum: '', 
    ppsNum: '',
    appointmentType: '',
    slotId: '',
    patientId: '',
    practitioner: '',
    email: ''
}

export default initialBookingState