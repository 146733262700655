export const PATIENT_DETAILS_STEP = 'Patient Details';
export const CHOOSE_REASON_STEP = 'Choose Reason';
export const CALENDAR_STEP = 'Calendar';
export const GATHER_DETAILS_STEP = 'Gather Details';
export const DEPOSIT_STEP = 'Deposit';
export const CAPTURED_DEPOSIT_STEP = 'Capture Deposit';
export const TEXT_VERIFICATION_STEP = 'Text Verification';
export const BOOKING_COMPLETE_STEP = 'Booking Complete';

export const stepList = [
    PATIENT_DETAILS_STEP,
    CHOOSE_REASON_STEP,
    CALENDAR_STEP,
    GATHER_DETAILS_STEP,
    CAPTURED_DEPOSIT_STEP,
    DEPOSIT_STEP,
    TEXT_VERIFICATION_STEP,
    BOOKING_COMPLETE_STEP
]