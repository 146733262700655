import { container } from "tsyringe";
import { IPracticeService } from "shared/Services/Practice/IPracticeService";
import React, { createContext, useState, useEffect } from "react";
import { IPractice } from "shared/Practices/Entities/IPractice";
import { useContext } from "react";
import { FatalErrorContext } from "./FatalErrorContext";
export interface PracticeContextI {
  practice: IPractice;
  setupComplete: boolean;
}
export const PracticeContext = createContext<PracticeContextI>({
  setupComplete: false,
} as PracticeContextI);

export const PracticeContextProvider: React.FC<{}> = ({ children }) => {
  const value = usePracticeContext();
  return (
    <PracticeContext.Provider value={value}>
      {children}
    </PracticeContext.Provider>
  );
};

const usePracticeContext = () => {
  const { fatalErrorSet } = useContext(FatalErrorContext);

  const [practice, practiceSet] = useState<IPractice>({} as IPractice);
  const [setupComplete, setupCompleteSet] = useState(false);

  const practiceService = container.resolve<IPracticeService>(
    "IPracticeService"
  );

  const fetchFromApi = async () => 
    practiceService
      .getCurrentPractice()
      .then(practiceSet)
      .catch((error) => {
        fatalErrorSet({message: error.response.data.message, statusCode: error.response.status});
      });
  
  useEffect(() => {
    Promise.resolve(fetchFromApi()).then(() => setupCompleteSet(true));
  }, []);
  return {
    practice,
    setupComplete,
  };
};
