import {ISlot} from "shared/Practices/Entities/ISlot";
import { ENDPOINT_SLOTS, ENDPOINT_BOOKING, ENDPOINT_APPOINTMENT_TYPES, ENDPOINT_LOCK_SLOT } from '../constants/endpoints'
import { getRequest, postRequest } from "shared/API/rest"
import IBooking from "shared/Practices/Entities/IBooking";
import { Dayjs } from "dayjs";

class SlotsAPI {
  listSlots(monthStartDate:Dayjs,appointmentType:string,practitioner:string = "") {
    let endpoint:string = ENDPOINT_SLOTS;
      
      return getRequest<any>(endpoint,
        {
          month:monthStartDate.month()+1,
          practitioner,
          appointmentType
        }).then((slotData:any)=>{
        let slots:Array<ISlot> = new Array<ISlot>();
        slotData.slots.forEach((slotDatum: ISlot) => {
          slots.push({
            ...slotDatum,
            slotDate: new Date(slotDatum.slotDate),
          })
        });
        return slots;
      }
    );

  }

  listTypes() {
      return getRequest<any>(ENDPOINT_APPOINTMENT_TYPES).then((appointmentTypes:any)=>{
        return appointmentTypes;
      }
    );

  }

  book(book:IBooking) {
    console.log('Book', book)
      return postRequest<any>(ENDPOINT_BOOKING,{...book}).then((booked:any)=>{
        return booked;
      })
  }

  lockSlot(slotId:string) {
    return postRequest<any>(ENDPOINT_LOCK_SLOT,{slotId}).then((success:any) =>{
      return success;
    })
  }

}

const slotsAPI = new SlotsAPI();
export default slotsAPI;
