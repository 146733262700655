import React, { useContext, useState } from 'react'
import useTextVerification from '../../hooks/useTextVerification'
import {TEXT_VERIFICATION_STEP, BOOKING_COMPLETE_STEP, CAPTURED_DEPOSIT_STEP, GATHER_DETAILS_STEP, CALENDAR_STEP, DEPOSIT_STEP} from './Steps'
import { useEffect } from 'react'
import { PracticeContext } from '../../contexts/PracticeContext'

const TextVerificationStep = (props: any) => {
    
    let {sendVerificationText,verifyCode} = useTextVerification()
    let [codeSent,setCodeSent] = useState(false);
    let [canRetry,setCanRetry] = useState(true);
    let [sendCodeFailure, setSendCodeFailure] = useState(false);
    let [codeVerified, setCodeVerified] = useState(false);
    let [wrongCode,setWrongCode] = useState(false);
    let [inputCode,setInputCode] = useState('');
    
    const { practice } = useContext(PracticeContext);
    const { setProgressState } = props
    setProgressState && setProgressState(TEXT_VERIFICATION_STEP);

    const sendText = () => {
        if(props.booking && (props.booking.mobile || props.booking.patientId) && canRetry) {
            setCodeSent(true);
            sendVerificationText(props.booking.mobile,props.booking.patientId)
            .then((result)=>{
                setCanRetry(result.retry);
                setCodeSent(result.success);
                setSendCodeFailure(!result.success);
            }).catch(()=>{
                setCodeSent(false);
                setSendCodeFailure(true);
            });
        }
    };

    const verify = (code:string) => {
        if(props.booking) {
            const key = props.booking.patientId ? props.booking.patientId : props.booking.mobile;
            verifyCode(key,code).then((verified:boolean)=>{
                if(verified) {
                    setCodeVerified(true);
                    setWrongCode(false);
                    nextStep();
                } else {
                    setCodeVerified(false);
                    setWrongCode(true);
                }
            }).catch(()=>{
                setCodeVerified(false);
                setWrongCode(true);
            })
        }
    };
    
    const nextStep = () => {
        if(props.practice) {
            if (props.practice.depositService && props.booking && props.booking?.appointmentDeposit !== 0) {
                return props.changeStep && props.changeStep(CAPTURED_DEPOSIT_STEP)
            } else if(props.practice.paymentService) {
                return props.changeStep && props.changeStep(DEPOSIT_STEP);
            } else {
                return props.changeStep && props.changeStep(BOOKING_COMPLETE_STEP);
            }
        }
    };

    const prevStep = () => {
        if(props.booking) {
            if(props.booking.patientId) {
                return props.changeStep && props.changeStep(CALENDAR_STEP);
            } else {
                return props.changeStep && props.changeStep(GATHER_DETAILS_STEP);
            }
        }
    };

    const handleChange = (e) => {
        setInputCode(e.target.value);
    };

    useEffect(() => {
        if(!codeSent) {
            sendText();
        }
    }, [props.booking]);

    return (
        <div className="o-wrap +no-pad@m- c-main +fixed-header">
            <div className="u-pull-v-center@m">
                <div className="u-pad">
                    {codeSent && wrongCode &&
                        <div className="c-alert +error">
                            Wrong code
                        </div>
                    }
                    {!canRetry &&
                        <div className="c-alert +error">
                            Text Verification attempts exceeded. Please Contact Dentist at {practice?.phoneNumber}.
                        </div>
                    }
                    {sendCodeFailure &&
                        <div className="c-alert +error">
                            Failed to send Text Verification. Please Contact Dentist at {practice?.phoneNumber}.
                        </div>
                    }
                    <p className="u-micro u-bold u-uppercase u-lighten">Confirm booking</p>
                    <h1 className="u-delta u-marg-bottom-half">Verify your booking</h1>
                    <p className="u-lighten">We have sent you a text message with a 6 digit verification code to confirm your booking.</p>

                    {codeSent && !codeVerified &&
                        <div className="c-form u-marg-bottom-x2">
                            <input type="text" className="c-input u-block u-marg-bottom" style={{width: '200px'}} onChange={handleChange}/>
                            <button className="c-button +large +primary u-marg-bottom" onClick={() => verify(inputCode) }>Verify Code</button>
                            {canRetry && <p>Didn't receive a code? <a href="#" onClick={sendText}>Send another verification code</a>.</p>}
                        </div>
                    }
                    <button className="c-button +large +ghost u-marg-right o-flex__item" onClick={() => props.changeStep && prevStep()}>Back</button>
                </div>
            </div>
        </div>
    )
}

export default TextVerificationStep
